@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

html {
    scroll-behavior: smooth;
}

body {
    color: black;
}

* {
    margin: 0;
    padding: 0;
    font-family: "Karla", sans-serif;
}
